import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import axios from 'axios';
import KEYS from '../../Keys';
import { authenticate } from '../../helpers/auth';
import { toast } from 'react-toastify';

export default function AddNewProduct() {
    const [state, setState] = React.useState({
        productName: '',
        productBrand: '',
        productShortDisc: '',
        productDisc: '',
        productKeywords: '',
        mrp: '',
        discount: '',
        price: '',
        productQty: '',
    });

    const addNow = () => {
        // if (state.username == '' || state.number == '' || state.email == '') {
        //     toast.error('Please fill all the fields')
        //     return
        // }

        axios.post(`${KEYS.NODE_URL}/api/product/new`, {
            productName: state.productName,
            productBrand: state.productBrand,
            productShortDisc: state.productShortDisc,
            productDisc: state.productDisc,
            productKeywords: state.productKeywords,
            mrp: state.mrp,
            discount: state.discount,
            price: state.price,
            productQty: state.productQty
        })
            .then(result => {
                console.log('result : ', result)
                // authenticate('abc', result, () => {
                // })
                toast.success(state.username + ', You are successfully Register & Credentials are send at gmail')
            }).catch(err => {
                console.log('imhere...', err)
            })
    }

    function isInt(value) {
        return !isNaN(value) && 
               parseInt(Number(value)) == value && 
               !isNaN(parseInt(value, 10));
      }
    
    const onChange = (e) => {
        if(e.target.name == "discount"){
            if(isInt(e.target.value)){
                var myprice = parseInt(state.mrp) - (parseInt(state.mrp) * parseInt(e.target.value)) / 100
            }else{
                var myprice = 0;
            }

            setState({
                ...state,
                price: myprice,
                discount: e.target.value
            })
            return
        }
        setState({ ...state, [e.target.name]: e.target.value });
    }

    return (
        <>
            <MainLayout nav_name='New Product'>
                <div className="row p-4">
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        {/* general form elements */}
                        {/* <div className="card card-success">
                        <div className="card-header">
                            <h3 className="card-title">Quick Example</h3>
                        </div> */}
                        {/* /.card-header */}
                        {/* form start */}
                        <form className='col-md-8 mt-0'>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="productName">Product Name *</label>
                                    <input type="text" className="form-control" id="productName" placeholder="Product Name" name='productName' onChange={onChange} value={state.productName} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="productBrand">Product Brand *</label>
                                    <input type="text" className="form-control" id="productBrand" placeholder="Brand" name='productBrand' onChange={onChange} value={state.productBrand} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="productShortDisc">Short Discription *</label>
                                    <input type="text" className="form-control" id="productShortDisc" placeholder="Short Discription" name='productShortDisc' onChange={onChange} value={state.productShortDisc} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="productDisc">Discription *</label>
                                    <input type="text" className="form-control" id="productDisc" placeholder="Discription" name='productDisc' onChange={onChange} value={state.productDisc} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="productKeywords">Keywords *</label>
                                    <input type="text" className="form-control" id="productKeywords" placeholder="Keywords" name='productKeywords' onChange={onChange} value={state.productKeywords} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mrp">MRP *</label>
                                    <input type="text" className="form-control" id="mrp" placeholder="MRP" name='mrp' onChange={onChange} value={state.mrp} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="discount">Discount</label>
                                    <input type="number" className="form-control" id="discount" placeholder="Discount" min='0' max='100' name='discount' onChange={onChange} value={state.discount} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="price">Price*</label>
                                    <input type="text" className="form-control" id="price" placeholder="Price" name='price' value={state.price} readOnly />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="productQty">Quantity *</label>
                                    <input type="text" className="form-control" id="productQty" placeholder="Product Quantity" name='productQty' onChange={onChange} value={state.productQty} />
                                </div>
                            </div>
                            {/* /.card-body */}
                            <div className="card-footer">
                                <button type="button" onClick={addNow} className="btn btn-success">Add</button>
                            </div>
                        </form>
                        {/* <div className="form-sidebar col-md-4 d-flex justify-content-center align-items-center">
                            <div className="img">
                                <img src="https://kb.spinbackup.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png" alt="saleman-photo" className="img-thumbnail" />
                                <label htmlFor="saleman-pic"><i className="far fa-edit"></i></label>
                                <input type="file" id="saleman-pic"/>
                            </div>
                        </div> */}
                        {/* </div> */}
                    </div>
                </div>

            </MainLayout>
        </>
    );
}
