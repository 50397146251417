import * as React from 'react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import KEYS from '../../Keys';
import axios from 'axios';
import { useStateValue } from '../../StateProvider/StateProvider';
import './OrderDetail.css'

export default function OrderDetail() {
    const navigate = useNavigate();
    const [store, dispatch] = useStateValue();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = React.useState({
        waitingState: true,
        order: ''
    })

    React.useEffect(() => {
        var id = searchParams.get("id");
        axios.post(`${KEYS.NODE_URL}/api/order/admin/one`, { id })
            .then(result => {
                console.log(result)
                setState({
                    ...state,
                    waitingState: false,
                    order: result.data.orders
                })
            }).catch(err => {
                console.log('imhere...', err)
                setState({
                    ...state,
                    waitingState: false,
                })
            })
    }, [])


    const openThis = _src => {
        window.open(_src, '_blank');
    }


    return (
        <>
            <MainLayout>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Order Detail</h3>
                                    </div>
                                    {
                                        state.waitingState ? <>Loading...</> :
                                            <>
                                                <div className='card-body'>
                                                    <table>
                                                        <tr>
                                                            <td>Order Id </td>
                                                            <td><b>{state.order[0][0]['basicInfo']['order_id']}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Date </td>
                                                            <td>{state.order[0][0]['basicInfo']['date']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Time </td>
                                                            <td>{state.order[0][0]['basicInfo']['time']}</td>
                                                        </tr>
                                                        <hr />
                                                        <tr>
                                                            <td>User Id </td>
                                                            <td>{state.order[0][0]['basicInfo']['user_id']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>User Name </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_firstname']} {state.order[0][0]['billingInfo']['b_lastname']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>User Email </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_email']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_phone']}</td>
                                                        </tr>
                                                        <hr />
                                                        <h4>Billing Address</h4>
                                                        <tr>
                                                            <td>Street Address </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_street']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>City </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_city']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>State </td>
                                                            <td>{state.order[0][0]['billingInfo']['b_state']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Zip/Pin Code</td>
                                                            <td>{state.order[0][0]['billingInfo']['b_zip']}</td>
                                                        </tr>
                                                        <hr />
                                                        <h4>Product</h4>
                                                        <tr>
                                                            <td>Name</td>
                                                            <td><b>Digital Graphic Signs</b> </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Model </td>
                                                            <td><b>{state.order[0][0]['productInfo']['p_model']}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Quantity </td>
                                                            <td><b>{state.order[0][0]['productInfo']['p_qty']} Pieces</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Unit-Price</td>
                                                            <td>C${state.order[0][0]['productInfo']['p_price']['price']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sub Total (Quantity * Unit-Price)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                            <td>{state.order[0][0]['productInfo']['p_qty']} * C${state.order[0][0]['productInfo']['p_price']['price']} = <span className='text-bold'>C${Math.round(state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty'])}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Coupon Discount</td>
                                                            <td><span className="text-danger text-bold">- C${(state.order[0][0]['productInfo']['p_qty'] * state.order[0][0]['productInfo']['p_discount']).toFixed(2)}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>HST Amount  </td>
                                                            <td>13% (<span className="text-success text-bold">+ C${(((state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty']) - state.order[0][0]['productInfo']['p_discount']) * 13 / 100).toFixed(2)}</span>)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Total Price</b></td>
                                                            <td><b className='text-success'>C${((((state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty']) - state.order[0][0]['productInfo']['p_discount']) * 13 / 100) + (((state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty']) - state.order[0][0]['productInfo']['p_discount']))).toFixed(2)}</b></td>
                                                        </tr>
                                                        <hr />
                                                        <h4>Payment</h4>
                                                        <tr>
                                                            <td>Type</td>
                                                            <td>Card [Prepaid]</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Id</td>
                                                            <td>{state.order[0][0]['basicInfo']['payment_id']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Amount</td>
                                                            <td><b className='text-success'>C${((((state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty']) - state.order[0][0]['productInfo']['p_discount']) * 13 / 100) + (((state.order[0][0]['productInfo']['p_price']['price'] * state.order[0][0]['productInfo']['p_qty']) - state.order[0][0]['productInfo']['p_discount']))).toFixed(2)}</b></td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>Status</td>
                                                            <td className='text-success text-bold'>Success</td>
                                                        </tr> */}
                                                        <hr />
                                                        <h4>Business Information</h4>
                                                        <tr>
                                                            <td>Website</td>
                                                            <td>{state.order[1]['social_accounts']['website']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Facebook</td>
                                                            <td>{state.order[1]['social_accounts']['facebook']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Instagram</td>
                                                            <td>{state.order[1]['social_accounts']['instagram']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Text</td>
                                                            <td>{state.order[1]['social_accounts']['txt']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Images</td>
                                                            <td className='img-div-admin'>
                                                                {state.order[1]['images'].map(_img => <img onClick={() => openThis(_img)} src={_img} alt="" />)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Videos</td>
                                                            <td className='img-div-admin'>
                                                                {state.order[1]['videos'].map(_vid => <video onClick={() => openThis(_vid)} width="200"><source src={_vid} type="video/mp4" /></video>)}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>

            </MainLayout>
        </>
    );
}
