import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import KEYS from '../../Keys';
import axios from 'axios';
import { useStateValue } from '../../StateProvider/StateProvider';


export default function ViewSaleman() {
    const [store, dispatch] = useStateValue();
    const [state, setState] = React.useState({
        waitingState: true,
    })

    React.useEffect(() => {
        axios.post(`${KEYS.NODE_URL}/api/saleman/all`)
            .then(result => {
                dispatch({
                    type: 'SET_SALEMEN',
                    salemen: result.data.allSaleman
                })
                setState({
                    ...state,
                    waitingState: false,
                })
            }).catch(err => {
                console.log('imhere...', err)
                setState({
                    ...state,
                    waitingState: false,
                })
            })
    }, [])
    return (
        <>
            <MainLayout>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Sale Man List</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {!state.waitingState ? (
                                        <div className="card-body">
                                            <table id="myTable" className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Phone</th>
                                                        <th>Sales</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        store.salemen.map((item, index) => (
                                                            <tr>
                                                                <td>{item._id}</td>
                                                                <td>{item.name}</td>
                                                                <td>{item.phone}</td>
                                                                <td>{'0'}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Phone</th>
                                                        <th>Sales</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ) : ( <center className='m-4'><h4>Loading...</h4></center> )}

                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>

            </MainLayout>
        </>
    );
}
