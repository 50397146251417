import React, { useEffect, useState } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import { useStateValue } from './StateProvider/StateProvider';
import { getAuthentication } from './helpers/auth';
import axios from 'axios';
import KEYS from './Keys';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// pages
import Dashboard from './admin/pages/Dashboard';
import Security from './admin/pages/Security';
import Profile from './admin/pages/Profile';
import ViewUser from './admin/pages/ViewUser';
import ViewSaleman from './admin/pages/ViewSaleman';
import AddNewSaleman from './admin/pages/AddNewSaleman';
import AdminLogin from './admin/pages/Login';
import MainLayout from './admin/includes/MainLayout';
import AddNewProduct from './admin/pages/AddNewProduct';
import ViewProducts from './admin/pages/ViewProducts';
import UpdateProduct from './admin/pages/updateProduct';
import Orders from './admin/pages/Orders';
import OrderDetail from './admin/pages/OrderDetail';


function App() {
  const [store, dispatch] = useStateValue();
  const [state, setState] = useState({
    isWait: false,
  });

  return (
    <>
      {state.isWait ?
        <center className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <b><span className="mt-2">Please Wait</span></b>
        </center> :
        <BrowserRouter>
          <Routes>
            <Route path="admin/saleman/new" element={<AddNewSaleman />} />
            <Route path="admin/saleman/all" element={<ViewSaleman />} />
            <Route path="admin/product/new" element={<AddNewProduct />} />
            <Route path="admin/product/:productId" element={<UpdateProduct />} />
            <Route path="admin/product/all" element={<ViewProducts />} />
            <Route path="admin/user/all" element={<ViewUser />} />
            <Route path="admin/profile" element={<Profile />} />
            <Route path="admin/security" element={<Security />} />
            <Route path="admin/dashboard" element={<Dashboard />} />
            <Route path="admin/login" element={<AdminLogin />} />
            <Route path="admin/orders" element={<Orders />} />
            <Route path="admin/orders/orderDetail" element={<OrderDetail />} />
            <Route path="admin/" element={<Dashboard />} />
            <Route path="admin/*" element={<MainLayout />} />
            <Route path="" element={<Dashboard />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
          />
        </BrowserRouter>}

    </>
  );
}

export default App;
