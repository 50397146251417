import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import { useStateValue } from '../../StateProvider/StateProvider';
import { authenticate } from '../../helpers/auth';
import axios from 'axios';
import KEYS from '../../Keys';
import { toast } from 'react-toastify';

export default function AdminLogin() {
    const [store, dispatch] = useStateValue();
    const [state, setState] = React.useState({
        phone: '',
        password: '',
        wait: false
    });

    const LoginNow = () => {
        setState({
            ...state,
            wait: true
        })
        // set token
        var result = {
            data: {
                token: 'thisismytoken',
            }
        }
        // axios.post(`${KEYS.NODE_URL}/api/admin/auth/signup`)
        // .then(result => {
        //     console.log(result)
        // }).catch(err => {
        //     console.log('imhere...', err)
        // })

        axios.post(`${KEYS.NODE_URL}/api/admin/auth/signin`, {
            phone: state.phone,
            password: state.password
        })
            .then(result => {
                setState({
                    ...state,
                    wait: false
                })
                // console.log(result.data);
                if (result.data.success == '1') {
                    toast.success('Login Successfully')
                    authenticate('admin', result, () => {
                        console.log("token is set successfully")
                        // now redirect
                        window.location.href = '/admin'
                    })
                } else {
                    toast.error('Error: ' + result.data.error)
                }
            }).catch(err => {
                console.log('imhere...', err)
                console.log('imhere...', err.data)
                setState({
                    ...state,
                    wait: false
                })
            })
    }

    const onChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <section className="vh-100">
                <div className="container py-5 h-100">
                    <div className="row d-flex align-items-center justify-content-center h-100">
                        <div className="col-md-8 col-lg-7 col-xl-6">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" className="img-fluid" alt="Phone image" />
                        </div>
                        <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                            <form>
                                {/* Email input */}
                                <div className="form-outline mb-4">
                                    <input type="text" id="form1Example13" name="phone" className="form-control form-control-lg" value={state.phone} onChange={onChange} />
                                    <label className="form-label" htmlFor="form1Example13">Phone</label>
                                </div>
                                {/* Password input */}
                                <div className="form-outline mb-4">
                                    <input type="password" id="form1Example23" name="password" className="form-control form-control-lg" value={state.password} onChange={onChange} />
                                    <label className="form-label" htmlFor="form1Example23">Password</label>
                                </div>
                                <div className="d-flex justify-content-around align-items-center mb-4">
                                    {/* Checkbox */}
                                    {/* <div className="form-check">
                                        <input className="form-check-input" type="checkbox" defaultValue id="form1Example3" defaultChecked />
                                        <label className="form-check-label" htmlFor="form1Example3"> Remember me </label>
                                    </div>
                                    <a href="#!">Forgot password?</a> */}
                                </div>
                                {/* Submit button */}
                                <button type="button" className="btn btn-primary btn-lg btn-block" onClick={!state.wait ? LoginNow : null }>{state.wait ? <><div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div></> : "Sign in"}</button>
                                {/* <div className="divider d-flex align-items-center my-4">
                                    <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
                                </div>
                                <a className="btn btn-primary btn-lg btn-block" style={{ backgroundColor: '#3b5998' }} href="#!" role="button">
                                    <i className="fab fa-facebook-f me-2" />Continue with Facebook
                                </a>
                                <a className="btn btn-primary btn-lg btn-block" style={{ backgroundColor: '#55acee' }} href="#!" role="button">
                                    <i className="fab fa-twitter me-2" />Continue with Twitter</a> */}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
