import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import axios from 'axios';
import KEYS from '../../Keys';
import { authenticate } from '../../helpers/auth';
import { toast } from 'react-toastify';

export default function AddNewSaleman() {
    const [state, setState] = React.useState({
        username : '',
        email: '',
        number: ''
    });
    
    const signupNow = () => {
        if(state.username == '' || state.number == '' || state.email == '')
        {
            toast.error('Please fill all the fields')
            return
        }
        axios.post(`${KEYS.NODE_URL}/api/saleman/auth/signup`, {
            name: state.username,
            phone: state.number,
            email: state.email
        })
        .then(result => {
            console.log('result : ',result)
            // authenticate('abc', result, () => {
            // })
            toast.success(state.username + ', You are successfully Register & Credentials are send at gmail')
        }).catch(err => {
            console.log('imhere...', err)
        })
    }

    const onChange = (e) => {
        setState({...state, [e.target.name]: e.target.value});
    }

    return (
        <>
            <MainLayout nav_name='Register as Saleman'>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        {/* general form elements */}
                        {/* <div className="card card-success">
                        <div className="card-header">
                            <h3 className="card-title">Quick Example</h3>
                        </div> */}
                        {/* /.card-header */}
                        {/* form start */}
                        <form className='col-md-8 mt-0'>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Your Name *</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Your Name" name='username' onChange={onChange} value={state.username}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Email</label>
                                    <input type="email" className="form-control" id="exampleInputPassword1" placeholder="Email" name='email' onChange={onChange} value={state.email}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Phone Number" name='number' onChange={onChange} value={state.number}/>
                                </div>

                            </div>
                            {/* /.card-body */}
                            <div className="card-footer">
                                <button type="button" onClick={signupNow} className="btn btn-success">Add</button>
                            </div>
                        </form>
                        {/* <div className="form-sidebar col-md-4 d-flex justify-content-center align-items-center">
                            <div className="img">
                                <img src="https://kb.spinbackup.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png" alt="saleman-photo" className="img-thumbnail" />
                                <label htmlFor="saleman-pic"><i className="far fa-edit"></i></label>
                                <input type="file" id="saleman-pic"/>
                            </div>
                        </div> */}
                        {/* </div> */}
                    </div>
                </div>

            </MainLayout>
        </>
    );
}
