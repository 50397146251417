import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import { getAuthentication } from '../../helpers/auth';
import axios from 'axios';
import KEYS from '../../Keys';
import { toast } from 'react-toastify';

export default function Profile() {
    const [profile, setProfile] = React.useState('')
    
    React.useEffect(()=>{
        var token = getAuthentication('admin'); 
        axios.post(`${KEYS.NODE_URL}/api/admin/profile`, {
            token,
        })
            .then(result => {
                console.log(result.data);
                if(result.data.success == '1'){
                    // toast.success('Login Successfully')
                    setProfile(result.data.admin)
                }else{
                    toast.error('Error: ' + result.data.error)
                }
            }).catch(err => {
                console.log('imhere...', err)
                console.log('imhere...', err.data)
            })
    }, [])

    return (
        <>
            <MainLayout nav_name={'My Profile'}>
                <div className="row">
                    <div className="col-md-12 d-flex">
                        {/* general form elements */}
                        {/* <div className="card card-success">
                        <div className="card-header">
                            <h3 className="card-title">Quick Example</h3>
                        </div> */}
                        {/* /.card-header */}
                        {/* form start */}
                        <form className='col-md-8 mt-4'>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Your Name *</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Your Name" value={profile.name}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="examplePhone1">Phone Number</label>
                                    <input type="email" className="form-control" id="examplePhone1" placeholder="Phone Number" value={profile.phone} readOnly/>
                                </div>

                            </div>
                            {/* /.card-body */}
                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Update Info</button>
                            </div>
                        </form>
                        {/* <div className="form-sidebar col-md-4 d-flex justify-content-center align-items-center">
                            <div className="img">
                                <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="saleman-photo" className="img-thumbnail" />
                                <label htmlFor="saleman-pic"><i className="far fa-edit"></i></label>
                                <input type="file" id="saleman-pic" style={{display: 'hidden'}}/>
                            </div>
                        </div> */}
                        {/* </div> */}
                    </div>
                </div>

            </MainLayout>
        </>
    );
}
