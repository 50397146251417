import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';

export default function Footer() {
    const datenow = new Date().getFullYear()
    return (
        <>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © {datenow} <a href="https://prismonic.ca">prismonic.com</a>. </strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 1.0.0
                </div>
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </>
    );
}
