import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';

export default function Security() {
    return (
        <>
            <MainLayout>
                <div className="row">
                    <div className="col-md-12 d-flex">
                        {/* general form elements */}
                        {/* <div className="card card-success">
                        <div className="card-header">
                            <h3 className="card-title">Quick Example</h3>
                        </div> */}
                        {/* /.card-header */}
                        {/* form start */}
                        <form className='col-md-8 mt-4'>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Old Password</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Old Password" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">New Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="New Password" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Confirm New Password</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Confirm New Password" />
                                </div>

                            </div>
                            {/* /.card-body */}
                            <div className="card-footer">
                                <button type="submit" className="btn btn-success">Change Password</button>
                            </div>
                        </form>
                        <div className="form-sidebar col-md-4 d-flex justify-content-center align-items-center">
                            <div className="img">
                                <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="saleman-photo" className="img-thumbnail" />
                                <label htmlFor="saleman-pic"><i className="far fa-edit"></i></label>
                                <input type="file" id="saleman-pic" style={{display: 'hidden'}}/>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

            </MainLayout>
        </>
    );
}
