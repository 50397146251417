import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import Header from './Header';
import Footer from './Footer';
import { useStateValue } from '../../StateProvider/StateProvider';
import AdminLogin from '../pages/Login';
import { getAuthentication } from '../../helpers/auth';
import axios from 'axios';
import KEYS from '../../Keys';

export default function MainLayout({ nav_name, children }) {
    const [store, dispatch] = useStateValue();
    const [wait, isWait] = React.useState(true)

    React.useEffect(() => {
        var adminToken = getAuthentication('admin');
        if (adminToken == undefined) {
            window.location.href = '/admin/login';
        }
        axios.post(`${KEYS.NODE_URL}/api/admin/auth/checkToken`, {
            token: adminToken
        })
            .then(result => {
                dispatch({
                    type: "LOGIN_ADMIN",
                    data: result.data.data
                })
                setTimeout(() => {
                    isWait(false);
                }, 1000);
            }).catch(err => {
                // console.log('imhere...', err)
                // console.log('imhere...', err.data)
                setTimeout(() => {
                    isWait(false);
                }, 1000);
            })

    }, [])
    return (
        <>
            {

                <div className="wrapper">
                    <Header nav_name={nav_name} />
                    <div className="content-wrapper">
                        {wait ?
                        <center className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                            <div className="spinner-grow" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <b><span className="mt-2">Please Wait</span></b>
                        </center> :
                        children }
                    </div>
                    <Footer />
                </div>
            }
        </>
    );
}
