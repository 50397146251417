import * as React from 'react';
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import KEYS from '../../Keys';
import axios from 'axios';
import { useStateValue } from '../../StateProvider/StateProvider';
import { Link, useSearchParams, useNavigate } from "react-router-dom";

export default function Orders() {
    const navigate = useNavigate();
    const [store, dispatch] = useStateValue();
    const [state, setState] = React.useState({
        waitingState: true,
        orders: []
    })

    React.useEffect(() => {
        axios.post(`${KEYS.NODE_URL}/api/order/admin/all`)
            .then(result => {
                console.log(result)
                setState({
                    ...state,
                    waitingState: false,
                    orders: result.data.orders
                })
            }).catch(err => {
                console.log('imhere...', err)
                setState({
                    ...state,
                    waitingState: false,
                })
            })
    }, [])
    return (
        <>
            <MainLayout>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Orders List</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {!state.waitingState ? (
                                        <div className="card-body">
                                            {
                                                state.orders.length == 0 ? <div className="col-12"><center>No Order</center></div> :
                                                    <table id="my_table" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Order ID</th>
                                                                <th>Date</th>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                state.orders.map((order, index) => (
                                                                    <tr key={index}>
                                                                        <td>{order[0]['basicInfo']['order_id']}</td>
                                                                        <td>{order[0]['basicInfo']['date']}, {order[0]['basicInfo']['time']}</td>
                                                                        <td>{order[1]['productName']}</td>
                                                                        <td>{order[0]['productInfo']['p_price']['price'] - order[0]['productInfo']['p_discount']}</td>
                                                                        <td><button className="btn btn-primary" onClick={() => {
                                                                            navigate(`orderDetail?id=${order[0]._id}`)
                                                                        }}>More</button></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                            }
                                        </div>
                                    ) : (<center className='m-4'><h4>Loading...</h4></center>)}

                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>

            </MainLayout>
        </>
    );
}
