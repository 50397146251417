import * as React from 'react';
import { Link } from "react-router-dom";
import { } from 'react-dom';
import MainLayout from '../includes/MainLayout';
import KEYS from '../../Keys';
import axios from 'axios';
import { useStateValue } from '../../StateProvider/StateProvider';
import { useNavigate } from "react-router-dom";


export default function ViewProducts() {
    let navigate = useNavigate(); 
    const [store, dispatch] = useStateValue();
    const [state, setState] = React.useState({
        waitingState: true,
    })

    React.useEffect(() => {
        axios.post(`${KEYS.NODE_URL}/api/product/all`)
            .then(result => {
                console.log(result)
                dispatch({
                    type: 'ADMIN_PRODUCTS_ALL',
                    admin_products: result.data.myProducts
                })
                setState({
                    ...state,
                    waitingState: false,
                })
            }).catch(err => {
                console.log('imhere...', err)
                setState({
                    ...state,
                    waitingState: false,
                })
            })
    }, [])

    const redirectToUpdate = (pid) => {
        let path = `/admin/product/${pid}`; 
        navigate(path);
    }

    return (
        <>
            <MainLayout>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row pt-4">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Products List</h3>
                                    </div>
                                    {/* /.card-header */}
                                    {!state.waitingState ? (
                                        <div className="card-body">
                                            <table id="myTable" className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        {/* <th>ID</th> */}
                                                        <th>Name</th>
                                                        <th>Price</th>
                                                        <th>Sales</th>
                                                        <th>Qty</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        store.admin_products.map((item, index) => (
                                                            <tr>
                                                                {/* <td>{item._id}</td> */}
                                                                <td>{item.productName}</td>
                                                                <td><b>$</b>{item.productPricing.price}</td>
                                                                <td>{item.productSales}</td>
                                                                <td>{item.productQty}</td>
                                                                <td>{'Active'}</td>
                                                                <td>
                                                                    <button className="btn btn-primary" onClick={() => redirectToUpdate(item._id)}>More</button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        {/* <th>ID</th> */}
                                                        <th>Name</th>
                                                        <th>Price</th>
                                                        <th>Sales</th>
                                                        <th>Qty</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ) : (<center className='m-4'><h4>Loading...</h4></center>)}

                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>

            </MainLayout>
        </>
    );
}
